import { Bar } from "react-chartjs-2";
import './index.css';
function calculateStepSize(data) {
  const values = data.datasets.flatMap(dataset => dataset.data);
  const max = Math.max(...values);
  const min = Math.min(...values);
  const range = max - min;
  const step = range / 8;
  const magnitude = Math.pow(10, Math.floor(Math.log10(step)));
  return Math.ceil(step / magnitude) * magnitude;
}
function RewardChart ({ chartData }) {
  const stepSize = calculateStepSize(chartData);
  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(tooltipItem) {
            const label = tooltipItem.dataset.label || '';
            const suffix = label.includes('APR') ? ' %' : label.includes('Rewards') ? ' CFX' : '';
            return `${label}: ${tooltipItem.formattedValue}${suffix}`;
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: { color: '#909497' }
      },
      'y-reward': {
        position: 'left',
        grid: { color: '#e0e0e0' },
        ticks: {
          color: '#909497',
          stepSize: stepSize,
          maxTicksLimit: 8
        },
      },
      'y-apr': {
        position: 'right',
        ticks: { color: '#909497' }
      }
    },
    maxBarThickness: 50
  };

  return (
    <div className="p-6 chart-container bg-base-200 rounded-2xl" style={{ height: '100%' }}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

export default RewardChart;